import React from "react";
import { ExpandingContentRowHeader } from "../../../Headers/ExpandingContentRowHeader";
import { Component } from "react";
import { PartFormProps } from "../../../../model";
import { translatedGradeOptions, translatedGradeRatingMap } from "../model";
import { find } from "lodash";
import ToolLinks from "../../Components/ToolLinks";
import { WithTranslation } from "react-i18next";
import { TahtiseuraField } from "../../Fields/TahtiseuraField";
import { TahtiseuraListField } from "../../Fields/TahtiseuraListField";

/*
 The form structure of a path part, used together with PathFormWrapper, which performs the necessary
 data operations for the form. Props that are passed to PartFormWrapper are passed along to this component
 */
export class YouthSportContentPart extends Component<
  PartFormProps & WithTranslation,
  any
> {
  render() {
    const { t, commentEdit, orgId, formData, tools, readOnlyMode } = this.props;
    const type = this.props.pathType;

    const readOnly = readOnlyMode;
    const partThemes =
      tools && tools.items && tools.items.length === 1
        ? tools.items[0].fields.partThemes
        : {};

    const options = translatedGradeOptions(t);
    const ratingMap = translatedGradeRatingMap(t);

    return (
      <React.Fragment>
        <ExpandingContentRowHeader
          title={t("shared.sportcontent.formHeader")}
          open={true}
        >
          {/*If FormSection has more than 1 children, it transforms to div with a class from className prop*/}
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="sportcontentSharedPath"
              tkey="shared.sportcontent.path"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="sportcontentSharedCoaching"
              tkey="shared.sportcontent.coaching"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.sportcontent.positive")}
            </div>
            <TahtiseuraListField
              t={t}
              name="sportcontentSharedPositive"
              placeholder="shared.sportcontent.placeholder"
              fieldTranslated="shared.sportcontent.positive"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.sportcontent.negative")}
            </div>
            <TahtiseuraListField
              t={t}
              name="sportcontentSharedNegative"
              placeholder="shared.sportcontent.placeholder"
              fieldTranslated="shared.sportcontent.negative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "sharedSportcontent"
            )}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("youth.sportcontent.passion.formHeader")}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="passionFairplay"
              tkey="youth.sportcontent.passion.fairplay"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="passionInvolvement"
              tkey="youth.sportcontent.passion.involvement"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="passionFeedbackKids"
              tkey="youth.sportcontent.passion.feedback"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              formfieldDesc="youth.sportcontent.passion.feedback.children"
              orgId={orgId}
            />
            <TahtiseuraField
              t={t}
              options={options}
              name="passionFeedbackYouth"
              tkey="youth.sportcontent.passion.feedback"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
              formfieldDesc="youth.sportcontent.passion.feedback.young"
              noLabel={true}
            />
            <TahtiseuraField
              t={t}
              options={options}
              name="passionFeedbackParents"
              tkey="youth.sportcontent.passion.feedback"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
              formfieldDesc="youth.sportcontent.passion.feedback.parents"
              noLabel={true}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.passion.positive")}
            </div>
            <TahtiseuraListField
              t={t}
              name="passionPositive"
              placeholder="youth.sportcontent.passion.placeholder"
              fieldTranslated="youth.sportcontent.passion.positive"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.passion.negative")}
            </div>
            <TahtiseuraListField
              t={t}
              name="passionNegative"
              placeholder="youth.sportcontent.passion.placeholder"
              fieldTranslated="youth.sportcontent.passion.negative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
          </div>
          <ToolLinks
            tools={find(partThemes, (x) => x.fields.type === "youthPassion")}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("youth.sportcontent.practice.formHeader")}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="practiceTotalAmount"
              tkey="youth.sportcontent.practice.totalAmount"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="practiceEncouragement"
              tkey="youth.sportcontent.practice.encouragement"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="practiceDiverse"
              tkey="youth.sportcontent.practice.diverse"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.practice.positive")}
            </div>
            <TahtiseuraListField
              t={t}
              name="practicePositive"
              placeholder="youth.sportcontent.practice.placeholder"
              fieldTranslated="youth.sportcontent.practice.positive"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.practice.negative")}
            </div>
            <TahtiseuraListField
              t={t}
              name="practiceNegative"
              placeholder="youth.sportcontent.practice.placeholder"
              fieldTranslated="youth.sportcontent.practice.negative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
          </div>
          <ToolLinks
            tools={find(partThemes, (x) => x.fields.type === "youthPractice")}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("youth.sportcontent.lifestyle.formHeader")}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="lifestyleImprovement"
              tkey="youth.sportcontent.lifestyle.improvement"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="lifestyleCommunicationParents"
              tkey="youth.sportcontent.lifestyle.communicationparents"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="lifestyleExample"
              tkey="youth.sportcontent.lifestyle.example"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="lifestyleAntidoping"
              tkey="youth.sportcontent.lifestyle.antidoping"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.lifestyle.positive")}
            </div>
            <TahtiseuraListField
              t={t}
              name="lifestylePositive"
              placeholder="youth.sportcontent.lifestyle.placeholder"
              fieldTranslated="youth.sportcontent.lifestyle.positive"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.lifestyle.negative")}
            </div>
            <TahtiseuraListField
              t={t}
              name="lifestyleNegative"
              placeholder="youth.sportcontent.lifestyle.placeholder"
              fieldTranslated="youth.sportcontent.lifestyle.negative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
          </div>

          <ToolLinks
            tools={find(partThemes, (x) => x.fields.type === "youthLifestyle")}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("youth.sportcontent.competition.formHeader")}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="competitionChoices"
              tkey="youth.sportcontent.competition.choices"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="competitionInvigorating"
              tkey="youth.sportcontent.competition.invigorating"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="competitionParticipation"
              tkey="youth.sportcontent.competition.participation"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="competitionClass"
              tkey="youth.sportcontent.competition.class"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="competitionFeedback"
              tkey="youth.sportcontent.competition.feedback"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.competition.positive")}
            </div>
            <TahtiseuraListField
              t={t}
              name="competitionPositive"
              placeholder="youth.sportcontent.competition.placeholder"
              fieldTranslated="youth.sportcontent.competition.positive"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.competition.negative")}
            </div>
            <TahtiseuraListField
              t={t}
              name="competitionNegative"
              placeholder="youth.sportcontent.competition.placeholder"
              fieldTranslated="youth.sportcontent.competition.negative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "youthCompetition"
            )}
          />
        </ExpandingContentRowHeader>
      </React.Fragment>
    );
  }
}

export default YouthSportContentPart as any;

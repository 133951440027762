import React from "react";
import { TFunction } from "i18next";
import FormFieldDescription from "./Forms/Fields/components/FormFieldDescription";

export function TahtiseuraLabel(props: { tkey: string; t: TFunction }) {
  const { tkey, t } = props;
  return (
    <>
      <div className="fieldlabel">
        <label className="formlabel">{t(`${tkey}.label`)}</label>
        <FormFieldDescription>{t(`${tkey}.help`)}</FormFieldDescription>
      </div>
    </>
  );
}

import React from "react";
import { ExpandingContentRowHeader } from "../../../Headers/ExpandingContentRowHeader";
import { Component } from "react";
import { PartFormProps } from "../../../../model";
import { translatedGradeOptions, translatedGradeRatingMap } from "../model";
import { find } from "lodash";
import ToolLinks from "../../Components/ToolLinks";
import { WithTranslation } from "react-i18next";
import { TahtiseuraField } from "../../Fields/TahtiseuraField";
import { TahtiseuraListField } from "../../Fields/TahtiseuraListField";
import {
  FormSectionWithContents,
  TahtiseuraFormSectionWithContentsProps,
} from "../../FormBuildHelpers";
import { TahtiseuraLabel } from "../../../TahtiseuraLabel";

/*
 The form structure of a path part, used together with PathFormWrapper, which performs the necessary
 data operations for the form. Props that are passed to PartFormWrapper are passed along to this component
 */
export class TopSportsManagementPart extends Component<
  PartFormProps & WithTranslation,
  any
> {
  render() {
    const {
      t,
      commentEdit,
      orgId,
      formData,
      tools,
      readOnlyMode,
      formMetaInfo,
      formVersion,
      sportsFederationTranslationKeyMap,
    } = this.props;
    const type = this.props.pathType;

    const readOnly = readOnlyMode;
    const partThemes =
      tools && tools.items && tools.items.length === 1
        ? tools.items[0].fields.partThemes
        : {};

    const options = translatedGradeOptions(t);
    const ratingMap = translatedGradeRatingMap(t);

    const formSectionProps: Omit<
      TahtiseuraFormSectionWithContentsProps,
      "partKey" | "sectionKey"
    > = {
      orgId,
      formVersion,
      formMetaInfo,
      sportsFederationTranslationKeyMap,
      t,
      formData,
      options,
      ratingMap,
      type,
      partThemes,
      readOnly,
      commentEdit,
    };

    return (
      <React.Fragment>
        <ExpandingContentRowHeader
          title={t("shared.management.leadership.formHeader")}
          open={true}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="leadershipValues"
              tkey="shared.management.leadership.values"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="leadershipVision"
              tkey="shared.management.leadership.vision"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="leadershipGoalmonitoring"
              tkey="shared.management.leadership.goalmonitoring"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="leadershipDigitaltools"
              tkey="shared.management.leadership.digitaltools"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraLabel
              t={t}
              tkey="shared.management.leadership.fairplay"
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="leadershipGoodgovernance"
              tkey="shared.management.leadership.goodgovernance"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="leadershipSafety"
              tkey="shared.management.leadership.safety"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="leadershipEquality"
              tkey="shared.management.leadership.equality"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="leadershipEnvironment"
              tkey="shared.management.leadership.environment"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="leadershipFairgame"
              tkey="shared.management.leadership.fairgame"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="leadershipChanges"
              tkey="shared.management.leadership.changes"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="leadershipStakeholders"
              tkey="shared.management.leadership.stakeholders"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="leadershipSelfassessment"
              tkey="shared.management.leadership.selfassessment"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.management.leadership.positive")}
            </div>
            <TahtiseuraListField
              t={t}
              name="leadershipPositive"
              placeholder="shared.management.leadership.placeholder"
              fieldTranslated="shared.management.leadership.positive"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.management.leadership.negative")}
            </div>
            <TahtiseuraListField
              t={t}
              name="leadershipNegative"
              placeholder="shared.management.leadership.placeholder"
              fieldTranslated="shared.management.leadership.negative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "topsportsManagementLeadership"
            )}
          />
        </ExpandingContentRowHeader>
        <FormSectionWithContents
          {...formSectionProps}
          partKey="management"
          sectionKey="topsports.management.topsportsleadership"
        />
        <ExpandingContentRowHeader
          title={t("shared.management.management.formHeader")}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="managementRules"
              tkey="shared.management.management.rules"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="managementFanancialstatement"
              tkey="shared.management.management.financialstatement"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
              hideRating={true}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="managementMemberslist"
              tkey="shared.management.management.memberslist"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraLabel
              t={t}
              tkey="shared.management.management.employer"
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="managementEmployment"
              tkey="shared.management.management.employment"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="managementWellfare"
              tkey="shared.management.management.wellfare"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="managementGuidebook"
              tkey="shared.management.management.guidebook"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="managementYearclock"
              tkey="shared.management.management.yearclock"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.management.management.positive")}
            </div>
            <TahtiseuraListField
              t={t}
              name="managementPositive"
              placeholder="shared.management.management.placeholder"
              fieldTranslated="shared.management.management.positive"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.management.management.negative")}
            </div>
            <TahtiseuraListField
              t={t}
              name="managementNegative"
              placeholder="shared.management.management.placeholder"
              fieldTranslated="shared.management.management.negative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "topsportsManagementManagement"
            )}
          />
        </ExpandingContentRowHeader>
        <FormSectionWithContents
          {...formSectionProps}
          partKey="management"
          sectionKey="topsports.management.topsportsmanagement"
        />
        <ExpandingContentRowHeader
          title={t("shared.management.communication.formHeader")}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="communicationMarketingstrategy"
              tkey="shared.management.communication.marketingstrategy"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="communicationWebsite"
              tkey="shared.management.communication.website"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="communicationSomeguide"
              tkey="shared.management.communication.someguide"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="communicationMarketing"
              tkey="shared.management.communication.marketing"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="communicationSupportnew"
              tkey="shared.management.communication.supportnew"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.management.communication.positive")}
            </div>
            <TahtiseuraListField
              t={t}
              name="communicationPositive"
              placeholder="shared.management.communication.placeholder"
              fieldTranslated="shared.management.communication.positive"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.management.communication.negative")}
            </div>
            <TahtiseuraListField
              t={t}
              name="communicationNegative"
              placeholder="shared.management.communication.placeholder"
              fieldTranslated="shared.management.communication.negative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "topsportsManagementCommunication"
            )}
          />
        </ExpandingContentRowHeader>
        <FormSectionWithContents
          {...formSectionProps}
          partKey="management"
          sectionKey="topsports.management.topsportscommunication"
        />
      </React.Fragment>
    );
  }
}

export default TopSportsManagementPart as any;

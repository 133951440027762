import React from "react";
import { Field } from "redux-form";
import AuditField from "./AuditField";
import SelectField from "./SelectField";
import { get } from "lodash";
import { CommentMode } from "../../../modules/comment";
import { TFunction } from "i18next";

export function TahtiseuraField(props: {
  name: string;
  tkey?: string;
  orgId: string | undefined;
  readOnly?: boolean;
  commentEdit?: boolean;
  type: string;
  options: { value: string; label: any }[];
  ratingMap: any;
  formData: any;
  formfieldDesc?: string;
  hideRating?: boolean;
  noLabel?: boolean;
  t: TFunction;
}) {
  const {
    name,
    tkey,
    orgId,
    readOnly,
    commentEdit,
    type,
    formData,
    options,
    ratingMap,
    noLabel,
    formfieldDesc,
    hideRating,
    t,
  } = props;
  return (
    <>
      <Field
        options={options}
        name={name}
        className={"pathdropdown"}
        component={SelectField}
        placeholder={t(`${tkey}.placeholder`)}
        label={noLabel ? " " : t(`${tkey}.label`)}
        helpText={noLabel ? " " : t(`${tkey}.help`)}
        disabled={readOnly}
        showAddDesc={true}
        showAttachmentField={true}
        formfieldDesc={formfieldDesc ? t(formfieldDesc) : undefined}
        descriptionEdit={!commentEdit}
        partKey={type}
        orgId={orgId}
        autosave={true}
        edit={commentEdit}
        hideInput={hideRating ?? false}
      />
      <AuditField
        ratingMap={ratingMap}
        edit={commentEdit}
        type={type}
        fieldName={name}
        orgId={orgId}
        commentMode={CommentMode.Field}
        fieldTranslated={t(`${tkey}.label`)}
        fieldValue={ratingMap[get(formData, name)]}
      />
    </>
  );
}

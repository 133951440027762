import React, { Component } from "react";
import Alert from "react-s-alert";
import "./app.scss";
import Navigation from "../../components/Navigation/Navigation";
import AlertConfirmationTemplate from "../../components/AlertConfirmationTemplate";
import { Routes } from "../../router";
import ErrorBoundary from "../../components/ErrorBoundary";
import { connect } from "react-redux";
import { authenticateUser } from "../../modules/account";
import { RouteComponentProps, withRouter } from "react-router";
import PathStartModal from "../../components/PathStartModal/PathStartModal";
import DescriptionModal from "../../components/DescriptionModal/DescriptionModal";
import ResetScrollPosition from "../../components/ResetScrollPosition";
import { fetchConfig } from "../../modules/config";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

type AppProps = ReturnType<typeof mapDispatchToProps>;

/**
 * Root component for the application. Acts as a container for
 * sub components.
 */
class App extends Component<AppProps, any> {
  componentDidMount() {
    const { authenticate, fetchConfig } = this.props;

    authenticate();
    fetchConfig();
  }

  // Routes are defined in router.tsx
  render() {
    return (
      <div className="app sicl-modal-open-blur">
        <Navigation />
        <ErrorBoundary>
          <PathStartModal />
          <DescriptionModal />
          <div className="inner">
            <ResetScrollPosition />
            <DndProvider backend={HTML5Backend}>
              <Routes />
            </DndProvider>
          </div>
        </ErrorBoundary>
        <Alert
          contentTemplate={AlertConfirmationTemplate}
          effect="stackslide"
          stack={{ limit: 1 }}
          position="top"
          timeout={3000}
          handleClose={() => Alert.closeAll()}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  authenticate: () => dispatch(authenticateUser()),
  fetchConfig: () => dispatch(fetchConfig()),
});

const AppConnected = connect(null, mapDispatchToProps)(App);

export default withRouter<RouteComponentProps<{}>, any>(AppConnected);

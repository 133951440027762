import React, { Component } from "react";
import { connect } from "react-redux";
import { ReduxState } from "../../reducers";
import "./ClubDashboard.scss";
import { RouteComponentProps, withRouter } from "react-router";
import { fetchSingleAuditRequest } from "../../modules/auditRequest";
import { fetchEnrollments, fetchSingleEnrollment } from "../../modules/path";
import { getClubDashboardUrl } from "../../routePaths";
import { WithTranslation, withTranslation } from "react-i18next";
import PathFormV1 from "../../components/Forms/V1/PathFormV1";
import PathFormV2 from "../../components/Forms/V2/PathFormV2";
import PathFormV3 from "../../components/Forms/V3/PathFormV3";
import PathFormV4 from "../../components/Forms/V4/PathFormV4";
import PathFormV5 from "../../components/Forms/V5/PathFormV5";
import PathFormV6 from "../../components/Forms/V6/PathFormV6";
import { FORMVERSION } from "../../model";
import { translateWithMarkdown } from "../../util/translateWithMarkdown";
import { getOrganizationAuditFormMetaInfo } from "../../modules/formMetaInfo";
import {
  GradeOptionsList,
  YesNoOptionsList,
} from "../../components/Forms/Components/FieldGradingOptionList";

type PathFormRouteComponentProps = RouteComponentProps<{
  orgId: string;
  part: string;
  type: string;
  requestId: string;
}>;

type PathFormProps = PathFormRouteComponentProps &
  WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class PathForm extends Component<PathFormProps, any> {
  constructor(props: PathFormProps) {
    super(props);
    this.state = {
      requestFetched: false,
    };
  }

  fetchAuditRequest = async (props: PathFormProps) => {
    const {
      match: {
        params: { requestId, orgId, type },
      },
      fetchClubEnrollments,
      fetchSingleAuditRequest,
      history,
      activeRequest,
    } = this.props;
    if (!props.enrollment || !activeRequest) {
      const enrollments = await fetchClubEnrollments(orgId);
      if (enrollments && Array.isArray(enrollments)) {
        const enrollment = enrollments.find(
          (x) =>
            (x.clubId ? x.clubId.toString() : null) === orgId &&
            x.qualityPath === type &&
            x.enrollmentTimestamp !== null
        );
        if (requestId && enrollment) {
          const result = await fetchSingleAuditRequest(
            requestId,
            enrollment.id!
          );
          if (!result) {
            history.push(getClubDashboardUrl(orgId));
          }
        }
      }
    }
  };

  componentDidMount() {
    const {
      match: {
        params: { requestId, orgId },
      },
      activeRequest,
      getAuditFormMetaInfo,
    } = this.props;
    if (!this.props.enrollment || (!activeRequest && requestId)) {
      this.fetchAuditRequest(this.props);
    }
    getAuditFormMetaInfo(parseInt(orgId, 10));
  }

  render() {
    const {
      match,
      currentOrg,
      enrollment,
      activeRequest,
      formMetaInfo,
      sportsFederationTranslationKeyMap,
    } = this.props;

    const formVersion = activeRequest ? activeRequest.formVersion : FORMVERSION;
    const t = translateWithMarkdown(this.props.t);
    const tWithoutMarkdown = this.props.t;

    let readOnlyMode = activeRequest ? activeRequest.submitted : true;
    if (!enrollment) {
      readOnlyMode = true;
    }

    const partIsBasicCriteria = match.params.part === "basiccriteria";
    const isTopSportsPath = match.params.type === "TOP_SPORTS";

    return (
      <div className="partcontainer">
        {!partIsBasicCriteria ? (
          <>
            <h1>{t(`${match.params.part}.title`)}</h1>
            {match.params.type === "YOUTH" &&
              match.params.part === "sportcontent" && (
                <div className="gradesWithBanner">
                  <div className="youthBanner">{t("part.childRightsText")}</div>
                </div>
              )}
            <div className="description">
              {t(
                isTopSportsPath
                  ? "part.generalDescriptionTopSports"
                  : "part.generalDescription",
                { markdown: { multiline: true } }
              )}
            </div>
            <GradeOptionsList />
          </>
        ) : (
          <>
            <h1>{t(`${match.params.part}.title`)}</h1>
            <div className="description">
              {t("part.basiccriteria.description", {
                markdown: { multiline: true },
              })}
            </div>
            <YesNoOptionsList />
          </>
        )}
        {formVersion === 1 ? (
          <PathFormV1
            currentOrg={currentOrg}
            enrollment={enrollment}
            activeRequest={activeRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={false}
            clubMode={true}
            formPart={match.params.part}
            pathType={match.params.type}
          />
        ) : formVersion === 2 ? (
          <PathFormV2
            currentOrg={currentOrg}
            enrollment={enrollment}
            activeRequest={activeRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={false}
            clubMode={true}
            formPart={match.params.part}
            pathType={match.params.type}
          />
        ) : formVersion === 3 ? (
          <PathFormV3
            currentOrg={currentOrg}
            enrollment={enrollment}
            activeRequest={activeRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={false}
            clubMode={true}
            formPart={match.params.part}
            pathType={match.params.type}
          />
        ) : formVersion === 4 ? (
          <PathFormV4
            currentOrg={currentOrg}
            enrollment={enrollment}
            activeRequest={activeRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={false}
            clubMode={true}
            formPart={match.params.part}
            pathType={match.params.type}
          />
        ) : formVersion === 5 ? (
          <PathFormV5
            currentOrg={currentOrg}
            enrollment={enrollment}
            activeRequest={activeRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={false}
            clubMode={true}
            formPart={match.params.part}
            pathType={match.params.type}
            formMetaInfo={formMetaInfo}
            sportsFederationTranslationKeyMap={
              sportsFederationTranslationKeyMap
            }
          />
        ) : formVersion === 6 ? (
          <PathFormV6
            currentOrg={currentOrg}
            enrollment={enrollment}
            activeRequest={activeRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={false}
            clubMode={true}
            formPart={match.params.part}
            pathType={match.params.type}
            formMetaInfo={formMetaInfo}
            sportsFederationTranslationKeyMap={
              sportsFederationTranslationKeyMap
            }
          />
        ) : (
          <div>
            {tWithoutMarkdown("common:error.missingFormVersion", {
              version: formVersion,
            })}
          </div>
        )}
      </div>
    );
  }
}

const translated = withTranslation("forms")(PathForm);

const mapStateToProps = (
  state: ReduxState,
  ownProps: PathFormRouteComponentProps
) => {
  const enrollment =
    state.path.clubEnrollments &&
    state.path.clubEnrollments.find(
      (x) =>
        (x.clubId ? x.clubId.toString() : null) ===
          ownProps.match.params.orgId &&
        x.qualityPath === ownProps.match.params.type &&
        x.enrollmentTimestamp !== null
    );
  const auditRequests =
    enrollment && enrollment.id
      ? state.auditRequest.requestLists[enrollment.id]
      : null;
  const activeRequest = auditRequests
    ? auditRequests.find(
        (x) =>
          x.id === parseInt(ownProps.match.params.requestId, 10) &&
          x.qualityPath === ownProps.match.params.type
      )
    : null;
  return {
    currentOrg: state.account.currentOrg,
    activeRequest,
    enrollment: enrollment,
    formMetaInfo:
      state.organizationAuditFormMetaInfo?.organizationAuditFormMetaInfo[
        ownProps.match.params.orgId
      ],
    sportsFederationTranslationKeyMap:
      state.config?.topSportsSportsFederationNameMap,
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchSingleAuditRequest: (requestId: string, enrollId: number) =>
    dispatch(fetchSingleAuditRequest(requestId, enrollId)),
  fetchSingleEnrollment: (orgId: string, type: string) =>
    dispatch(fetchSingleEnrollment(orgId, type)),
  fetchClubEnrollments: (orgId: string) => dispatch(fetchEnrollments(orgId)),
  getAuditFormMetaInfo: (orgId: number) =>
    dispatch(getOrganizationAuditFormMetaInfo(orgId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(translated)
);
